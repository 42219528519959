
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    filter: {
        country: {
            name: ""
        },
        geo: [{
            id: ""
        }],
        contact: [],
        with_contact: [{
            type: "",
            action: "should"
        },
        {
            type: "",
            action: "should"
        }
        ],
        relevance: {
            value: ""
        },
        audience_relevance: {
            value: ""
        },
        text: "",
        age: {
            left_number: "",
            right_number: ""
        },
        followers: {
            left_number: "",
            right_number: ""
        },
        engagements: {
            left_number: "",
            right_number: ""
        },
        reels_plays: {
            left_number: "",
            right_number: ""
        },
        views: {
            left_number: "",
            right_number: ""
        },
        gender: {
            code: ""
        },
        engagement_rate: {
            value: 0,
            operator: "gte"
        },
        keywords: ""

    },
    influencers: {
        count: 0,
        data: []
    }

};





const disoveryfilterlice = createSlice({
    name: "discoveryData",
    initialState,
    reducers: {
        setToFrom: (state, action) => {
            if (action.payload.type === "left_number") {
                state.filter[action.payload.name].left_number = action.payload.value
            }
            if (action.payload.type === "right_number") {
                state.filter[action.payload.name].right_number = action.payload.value
            }
        },
        setEngagementRate: (state, action) => {
            if (action.payload.value) {
                state.filter[action.payload.name].value = action.payload.value
            }
            if (action.payload.name === "relevance" || action.payload.name === "audience_relevance") {
                state.filter[action.payload.name].value = action.payload.value
            }

        },
        setGender: (state, action) => {
            if (action.payload.value) {
                state.filter[action.payload.name].code = action.payload.value
            }
        },
        setCountry: (state, action) => {
            if (action.payload.id) {
                state.filter.geo[0].id = action.payload.id
            }
            if (action.payload.country) {
                state.filter[action.payload.name].name = action.payload.country
            }
        },
        setContact: (state, action) => {
            if (action.payload.data.includes("Has Phone")) {
                state.filter[action.payload.name][0].type = "phone"
                state.filter.contact = action.payload.data
            }
            else if (action.payload.data.includes("Has Email")) {
                state.filter[action.payload.name][1].type = "email"
                state.filter.contact = action.payload.data
            } else {
                state.filter[action.payload.name][0].type = ""
                state.filter[action.payload.name][1].type = ""
                state.filter.contact = action.payload.data
            }


        },
        setBio: (state, action) => {
            if (action.payload.value) {
                state.filter[action.payload.name] = action.payload.value
            }

        },


        setMutlipleCheckbox: (state, action) => {
            if (action.payload.checked) {
                if (state.filter[action.payload.name]) {
                    state.filter[action.payload.name].push(action.payload.value);
                } else {
                    state.filter[action.payload.name] = [action.payload.value];
                }
            } else {
                if (state.filter[action.payload.name]) {
                    state.filter[action.payload.name] = state.filter[
                        action.payload.name
                    ].filter((option) => option !== action.payload.value);
                } else {
                }
            }
        },
        addInfluencers: (state, action) => {
            if (action.payload.data) {
                state.influencers.count += action.payload.count;
            }
            state.influencers.data = [...state.influencers.data, action.payload.data]
        },
        emptyInfluencers: (state, action) => {
            state.influencers.count = 0;
            state.influencers.data = []

        },
        setMinMaxRange: (state, action) => {
            state.filter[`min_${action.payload.name}`] = action.payload.value[0];
            state.filter[`max_${action.payload.name}`] = action.payload.value[1];
        },
        setRangeOnInputChange: (state, action) => {
            if (action.payload.value === "") {
                state.filter[`${action.payload.type}_${action.payload.name}`] = 0;
            } else {
                state.filter[`${action.payload.type}_${action.payload.name}`] =
                    action.payload.value;
            }
        },
        resetValues: (state, action) => {
            if (action.payload.name === "country") {
                state.filter[action.payload.name] = "";
            } else {
                state.filter[action.payload.name] = [];
            }
        },
        setLanguage: (state, action) => {
            if (action.payload.value) {
                state.filter.language[0] = action.payload.value;
            } else {
                state.filter.language[0] = "";
            }
        },
        resetfilter: () => initialState,
    },
});

export const {
    setToFrom,
    resetfilter,
    setEngagementRate,
    setGender,
    setCountry,
    setBio,
    setContact,
    setRangeOnInputChange,
    resetValues,
    setLanguage,
    addInfluencers,
    emptyInfluencers
} = disoveryfilterlice.actions;
export default disoveryfilterlice.reducer;