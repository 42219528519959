import { AccordionActions } from "@material-ui/core";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  data: { language: [], secondary_categories: [] },
  youtubeInfData: {},
  youtubeInfBio: {},
  instaAvelable: false,
  youTubeDataStatus: "loading",
  status: "loading",
  ytBioDataStatus: "loading",
  costSheetData: {},
};

export const getInfluencerInfo = createAsyncThunk(
  "influencerInfoData/getInfluencerInfo",
  async (id) => {
    const { data } = await axios.get("/influencer/?instagram_id=" + id);
    return data;
  }
);
export const getInfluencerInfoDataByHandel = createAsyncThunk(
  "influencerInfoData/getInfluencerInfoDataByHandel",
  async (handle) => {
    const { data } = await axios.get(
      `influencer/search/?search_string=${handle}`
    );
    if (data.data.lenght !== 0) {
      const findData = data.data;
      return findData[0];
    } else {
      const emp = [];
      return emp;
    }
  }
);
//get Youtube Inf Data
export const youtubeDataGet = createAsyncThunk(
  "influencerInfoData/youtubeDataGet",
  async (youtubeUrl) => {
    // const {data} = await axios.get(`/youtube/profile/data/?instagram_id=${instaId}`);
    const { data } = await axios.get(
      `/youtube/profile/data/?youtube_url=${youtubeUrl}`
    );
    return data.data;
  }
);

//new API to getting a youtube data
export const newYtDataGetInf = createAsyncThunk(
  ///youtube/profile/data/?youtube_url=
  "influencerInfoData/newYtDataGetInf",
  async (url) => {
    //const { data } = await axios.get(`/youtube/influencer/details/?youtube_url=${url}`);
    const { data } = await axios.get(
      `/youtube/profile/data/?youtube_url=${url}`
    );

    return data.data;
  }
);

//get youtube bio data
export const getYtBioData = createAsyncThunk(
  "influencerInfoData/getYtBioData",
  async (d) => {
    const { data } = await axios.get(
      `/youtube/influencer/details/?youtube_url=${d.youtube_url}`
    );
    return data.data;
  }
);

const influencerInfoSlice = createSlice({
  name: "influencerInfoData",
  initialState,
  reducers: {
    setYoutubeInfData: (state, action) => {
      state.youtubeInfData = action.payload;
    },
    setYoutubeInfBio: (state, action) => {
      state.youtubeInfBio = action.payload;
    },
    updateField: (state, action) => {
      if (action.payload.platform_type === "instagram") {
        if (Number(action.payload.value)) {
          state.data[action.payload.name] = Math.abs(
            parseInt(action.payload.value)
          );
        } else {
          state.data[action.payload.name] = action.payload.value;
        }
      }
      if (action.payload.platform_type === "youtube") {
        if (action.payload.instaAvel === true) {
          if (Number(action.payload.value)) {
            state.data[action.payload.name] = Math.abs(
              parseInt(action.payload.value)
            );
          } else {
            state.data[action.payload.name] = action.payload.value;
          }
        } else {
          if (
            Number(action.payload.value) === 0 ||
            Number(action.payload.value)
          ) {
            if ( 
              action.payload.name === "email" ||
              action.payload.name === "phone" ||
              action.payload.name === "pincode" ||
              action.payload.name === "scraper_phone" ||
              action.payload.name === "alternate_phone" ||
              action.payload.name === "tier" || action.payload.name === "account_number" ||
              action.payload.name === "pan_number" || action.payload.name === "bank_name"

            ) {
              state.youtubeInfBio[action.payload.name] = action.payload.value;
            }
            if (
              action.payload.name === "instagram_url" ||
              action.payload.name === "name" ||
              action.payload.name === "scraper_email" ||
              action.payload.name === "alternate_email"  ||
              action.payload.name ===  "address" || 
              action.payload.name ===  "region" || action.payload.name === "holders_name" || action.payload.name === "account_type" ||
              action.payload.name === "ifsc_code" || action.payload.name === "bank_city" || action.payload.name === "bank_address" ||
              action.payload.name === "primary_category"
            ) {
              if (action.payload.value === "") {
                state.youtubeInfBio[action.payload.name] = "";
              } else {
                state.youtubeInfBio[action.payload.name] = action.payload.value;
              }  
            } else {
              state.youtubeInfData[0][action.payload.name] = Math.abs(
                parseInt(action.payload.value)
              );
            }
          } else {
            if (
              action.payload.name === "instagram_url" ||
              action.payload.name === "name" ||
              action.payload.name === "scraper_email" ||
              action.payload.name === "alternate_email" ||
              action.payload.name ===  "address" ||
              action.payload.name ===  "region" 
            ) {
              state.youtubeInfBio[action.payload.name] = action.payload.value;
            } else {
              state.youtubeInfBio[action.payload.name] = action.payload.value;
            }
          }
        }
        //Handle youtube content and engagement
        if (
          action.payload.name === "youtube_integrated_video_pricing" ||
          action.payload.name === "youtube_dedicated_video_pricing"
        ) {
          state.youtubeInfData[0][action.payload.name] = Math.abs(
            parseInt(action.payload.value)
          );
        }
      }
    },
    updateValueToArray: (state, action) => {
      state.data[action.payload.name] = [action.payload.value];
    },
    updateLanguage: (state, action) => {
      if (action.payload.platform_type === "instagram") {
        state.data.language[action.payload.index] = action.payload.value;
      }
      if (action.payload.platform_type === "youtube") {
        if (action.payload.instaAvel) {
          state.data.language[action.payload.index] = action.payload.value;
        } else {
          state.youtubeInfBio.language[action.payload.index] =
            action.payload.value;
        }
      }
    },
    updateSecondaryCategories: (state, action) => {
      if (action.payload.platform_type === "instagram") {
        state.data.secondary_categories[action.payload.index] =
          action.payload.value;
      }
      if (action.payload.platform_type === "youtube") {
        if (action.payload.instaAvel === true) {
          state.data.secondary_categories[action.payload.index] =
            action.payload.value;
        } else {
          state.youtubeInfBio.secondary_categories[action.payload.index] =
            action.payload.value;
        }
      }
    },
    updatedCostSheet: (state, action) => {
      if (action.payload.input) {
        state.costSheetData.content_plan[action.payload.idx].price = Math.abs(
          parseInt(action.payload.value)
        );
      } else {
        state.costSheetData = action.payload;
      }
    },
    instaAvelableRedux: (state, action) => {
      state.instaAvelable = true;
    },
    falseInsataAvelRedux: (state, action) => {
      state.instaAvelable = false;
    },
    emptyInstaInfData: (state, action) => {
      state.data = { language: [], secondary_categories: [] };
      state.status = "loading";
      state.instaAvelable = false;
      state.youtubeInfData = {};
      state.youtubeInfBio = {};
      state.youTubeDataStatus = "loading";
      state.ytBioDataStatus = "loading";
      state.instaAvelable = false;
    },
    emptyYoutubeInfData: (state, action) => {
      state.instaAvelable = false;
      state.youtubeInfData = {};
      state.youtubeInfBio = {};
      state.youTubeDataStatus = "loading";
      state.ytBioDataStatus = "loading";
      state.instaAvelable = false;
    },
  },
  extraReducers: {
    [getInfluencerInfo.pending]: (state, action) => {
      state.status = "loading";
    },
    [getInfluencerInfo.fulfilled]: (state, action) => {
      state.status = "success";
      state.data = action.payload.data;
    },
    [getInfluencerInfo.rejected]: (state, action) => {
      state.status = "failed";
    },
    [getInfluencerInfoDataByHandel.pending]: (state, action) => {
      state.status = "loading";
    },
    [getInfluencerInfoDataByHandel.fulfilled]: (state, action) => {
      state.status = "success";
      state.data = action.payload;
    },
    [getInfluencerInfoDataByHandel.rejected]: (state, action) => {
      state.status = "failed";
    },
    [youtubeDataGet.pending]: (state, action) => {
      state.youTubeDataStatus = "loading";
    },
    [youtubeDataGet.fulfilled]: (state, action) => {
      state.youTubeDataStatus = "success";
      state.youtubeInfData = action.payload;
    },
    [youtubeDataGet.rejected]: (state, action) => {
      state.youTubeDataStatus = "failed";
    },
    [newYtDataGetInf.pending]: (state, action) => {
      state.youTubeDataStatus = "loading";
    },
    [newYtDataGetInf.fulfilled]: (state, action) => {
      state.youTubeDataStatus = "success";
      state.youtubeInfData = action.payload;
    },
    [newYtDataGetInf.rejected]: (state, action) => {
      state.youTubeDataStatus = "failed";
    },

    [getYtBioData.pending]: (state, action) => {
      state.ytBioDataStatus = "loading";
    },
    [getYtBioData.fulfilled]: (state, action) => {
      state.ytBioDataStatus = "success";
      state.youtubeInfBio = action.payload;
    },
    [getYtBioData.rejected]: (state, action) => {
      state.ytBioDataStatus = "failed";
    },
  },
});

export const {
  setData,
  updateField,
  updateValueToArray,
  updateLanguage,
  setYoutubeInfBio,
  updatedCostSheet,
  updateSecondaryCategories,
  setYoutubeInfData,
  instaAvelableRedux,
  falseInsataAvelRedux,
  emptyInstaInfData,
  emptyYoutubeInfData,
} = influencerInfoSlice.actions;
export default influencerInfoSlice.reducer;
